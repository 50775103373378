import {getDate} from "../js/tool";

export const applyStatus = [
    {key: 1, title: '审批中'},
    {key: 2, title: '已驳回'},
    {key: 3, title: '已通过'},
]
export const getApplyStatusText = function(status) {
    let res = applyStatus.find(s => s.key == status);
    return res ? res.title : null;
}

export const getRelyFlag = function(rely, value) {
    let flag = false;
    let val = rely.value;
    if(rely.valueKey && value) {
        value = value[rely.valueKey];
    }
    if(Object.prototype.toString.call(val) === "[object Array]") {
        flag = val.indexOf(value) >= 0;
    } else {
        flag = value === val;
    }
    return flag;
}

export const dealApply = function(apply) {
    apply.statusText = getApplyStatusText(apply.status);
    // 解析申请内容
    try {
        let config = JSON.parse(apply.temp_snap)
        let detail = JSON.parse(apply.detail);
        let data = [
            {
                label: '提交时间',
                value: getDate(apply.create_time).pattern( 'yyyy-MM-dd HH:mm')
            },
            {
                label: '申请人',
                value: apply.user ? apply.user.real_name || apply.user.uname: '无'
            }
        ];
        let imgList = [];
        for(let i = 0, l = config.length; i < l; i++) {
            let item = config[i];
            let value = detail[item.key];
            if(item.rely && !getRelyFlag(item.rely, detail[item.rely.key])) {
                continue;
            } else {
                let res = {
                    label: item.label,
                    key: item.key,
                    component: item.component,
                    value
                };
                if(item.component == 'upload') {
                    res.className = 'vertical';
                    value = typeof value == 'string' ? [value] : value;
                    res.value = value;
                    imgList = imgList.concat(value);
                } else if(item.component == 'timePeriod') {
                    res.value = value ? value.join(" ") : '';
                } else if(item.component == 'select' && item.rangeKey) {
                    res.value = value ? value[item.rangeKey] : '';
                }
                data.push(res);
            }
        }
        apply.imgList = imgList;
        apply.data = data;
        apply.parsedDetail = detail;
    } catch (e) {
        console.error(e)
    }
    // 解析审批流
    try {
        if(apply.flow_status) {
            let remark = JSON.parse(apply.flow_status);
            remark.time = getDate(apply.update_time).pattern("yyyy-MM-dd HH:mm");
            apply.remark = remark;
            let admin = apply.admin;
            if(admin && admin.length > 0) {
                for(let i = 0, l = admin.length; i < l; i++) {
                    let item = admin[i];
                    if(item.id == remark.id) {
                        item.remark = remark;
                        break;
                    }
                }
                admin.sort(a => {
                    return a.flow ? -1 : 0;
                });
            }
        }
    } catch (e) {
        console.error(e)
    }
}
