<template>
    <div class="content md flex-box">
        <address-book
            class="org-tree"
            ref="orgTree"
            @org="handleOrg"
            @select="handleSelect"></address-book>
        <div class="flex-grow flex-box vertical org-info" v-if="item">
            <div class="flex-box align-center org-info-top">
                <div class="flex-grow org-top-title">{{item.name}}</div>
                <a-space v-show="!edit">
                    <a-button v-if="ids.length == 1" @click="addDept">添加部门</a-button>
                    <template v-else-if="ids.length == 2">
                        <a-button @click="addPerson">添加成员</a-button>
                        <a-button type="primary" @click="editDept">编辑部门</a-button>
                    </template>
                    <template v-else-if="ids.length == 3">
                        <a-button @click="changePassword">修改密码</a-button>
                        <a-button type="primary" @click="editPerson">编辑成员</a-button>
                    </template>
                </a-space>
            </div>
            <div class="flex-grow flex-box vertical org-info-ctx">
                <form-area
                    ref="orgForm"
                    class="flex-grow flex-box vertical org-form"
                    layout="horizontal"
                    :label-col="{span: 4}"
                    :wrapper-col="{span: 20}"
                    :items="items"
                    :form="form"
                    @cancel="cancel"
                    @confirm="confirm" v-show="edit && type > 0"></form-area>
                <a-descriptions class="org-detail" title="成员详情" :column="1" v-show="!edit && ids.length == 3" v-if="item">
                    <a-descriptions-item label="姓名">{{item.name}}</a-descriptions-item>
                    <a-descriptions-item label="手机号码">{{item.tel}}</a-descriptions-item>
                    <a-descriptions-item label="部门">{{item.deptName}}</a-descriptions-item>
                    <a-descriptions-item label="角色">{{getRoleTypeText(item.role)}}</a-descriptions-item>
                </a-descriptions>
            </div>
        </div>
    </div>
</template>

<script>
    import addressBook from "../components/address-book";
    import {deptItems, adminItems, passwordItems, getRoleTypeText} from "../common/constant/org";
    import {clone} from "../common/js/tool";

    export default {
        name: "Organization",
        components: {
            addressBook
        },
        data() {
            return {
                ids: [],
                type: null, // 1 部门 2成员
                form: {},
                edit: false,
                item: null,
                groupList: []
            }
        },
        computed:{
            items() {
                let type = this.type;
                let res = [];
                if(type == 1) {
                    res = clone(deptItems);
                } else if(type == 2) {
                    res = clone(adminItems);
                    let form = this.form;
                    res[3].props.options = this.groupList.map(item => {
                        return {
                            key: item.id,
                            title: item.name
                        }
                    });
                    // 编辑去除密码编辑
                    if(form.id) {
                        res.splice(2, 1);
                    }
                } else if(type == 3) {
                    res = clone(passwordItems);
                }
                return res;
            }
        },
        methods: {
            getRoleTypeText,
            changePassword() {
                this.form = {id: this.item.id};
                this.type = 3;
                this.edit = true;
            },
            addDept() {
                this.form = {};
                this.type = 1;
                this.edit = true;
            },
            editDept() {
                this.form = clone(this.item);
                this.type = 1;
                this.edit = true;
            },
            addPerson() {
                this.form = {
                    dept: this.item.id
                };
                this.type = 2;
                this.edit = true;
            },
            editPerson() {
                let person = clone(this.item);
                person.role = person.role * 1;
                this.form = person;
                this.type = 2;
                this.edit = true;
            },
            cancel() {
                this.edit = false;
                this.type = null;
                this.form = {};
            },
            confirm() {
                let form = this.form, type = this.type;
                let url;
                if(type == 3) {
                    if(form.password == form.second) {
                        url = '/admin';
                        form = {
                            id: form.id,
                            password: form.password
                        }
                    } else {
                        this.$message.warning("两次输入密码不一致");
                        return;
                    }
                } else if(type == 1) {
                    url = '/admin-dept';
                } else if(type == 2) {
                    url = '/admin'
                }
                if(!url) return;
                let param = {
                    data: form
                }
                if(form.id) {
                    url += `/${form.id}`;
                    param.method = 'PATCH'
                } else {
                    param.method = 'POST'
                }
                param.url = url;
                this.$axios(param).then(res => {
                    if(form.id) {
                        this.item = res;
                    }
                    this.$message.success(form.id ? '更新成功' : '添加成功');
                    this.cancel();
                    this.updateList();
                })
            },
            handleOrg(list) {
                this.groupList = list;
            },
            handleSelect(e) {
                let {ids, item} = e;
                this.ids = ids;
                this.item = item;
                this.cancel();
            },
            updateList() {
                this.$refs.orgTree.getList();
            },
        }
    }
</script>

<style scoped lang="less">
    .org-tree {
        width: 240px;
        border-right: var(--border);
        overflow: auto;
    }
    .org-detail {
        margin-top: 10px;
    }
    .org-info {
        padding-left: 16px;
    }
    .org-info-top {
        padding-bottom: 16px;
        border-bottom: var(--border);
    }
    .org-top-title {
        font-size: 16px;
    }
</style>
