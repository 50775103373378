<template>
    <div class="flex-grow flex-box vertical">
        <data-list
                ref="dataList"
                class="content"
                add-btn=""
                name="申请"
                hide-edit
                hide-delete
                :url="url"
                :query="query"
                :columns="columns"
                :default-query="defaultQuery"
                :search-key-type="searchKeyType">
            <template #title>
                <span>申请列表</span>
            </template>
            <template v-slot:action="{item}">
                <a class="txt-btn" @mousedown.stop @mouseup.stop @click.stop="showDetail(item)">查看详情</a>
            </template>
            <template v-slot:query="{form}">
                <a-form-model-item label="">
                    <a-select v-model="form.type" :options="processTypes" placeholder="选择申请类型查询" style="width: 120px" @change="getList"></a-select>
                </a-form-model-item>
                <a-form-model-item label="">
                    <a-select v-model="form.apply_status" :options="applyStatus" placeholder="选择审批状态查询" style="width: 120px" @change="getList"></a-select>
                </a-form-model-item>
            </template>
        </data-list>
        <apply-detail :message="message" approve v-show="message" @operate="getList" @hide="hideApply"></apply-detail>
    </div>
</template>

<script>
    import applyDetail from "../components/apply-detail";
    import {getUserId} from "../common/js/storage";
    import {applyStatus, getApplyStatusText} from "../common/constant/apply";
    import {processTypes, getProcessTypeText} from "../common/constant/process";

    export default {
        name: "Approve",
        components: {
            applyDetail
        },
        data() {
            return {
                url: '/apply-message',
                query: `&sort=-id,&expand=apply,user,admin&filter[admin_id][like]=${getUserId()}`,
                defaultQuery: {apply_status: 1, type: 0},
                searchKeyType: {type: 2},
                applyStatus: [{key: 0, title: '全部'}].concat(applyStatus),
                processTypes: [{key: 0, title: '全部'}].concat(processTypes),
                columns: [
                    {title: '申请时间', dataIndex: 'create_time'},
                    {
                        title: '申请人',
                        customRender: (text, record) => {
                            return <span>{record.apply && record.apply.user ? record.apply.user.real_name || record.apply.user.uname : '-'}</span>
                        }
                    },
                    {
                        title: '业务类型',
                        dataIndex: 'apply',
                        customRender: text => {
                            return <span>{text ? getProcessTypeText(text.type) : '-'}</span>
                        }
                    },
                    {
                        title: '状态',
                        dataIndex: 'apply_status',
                        customRender: text => {
                            return <span>{getApplyStatusText(text)}</span>
                        }
                    }
                ],
                message: null
            }
        },
        computed: {
            dataList() {
                return this.$refs.dataList;
            }
        },
        methods: {
            getList() {
                this.dataList.getList();
            },
            hideApply() {
                this.message = null
            },
            showDetail(item) {
                this.message = item;
            }
        }
    }
</script>

<style scoped>

</style>
