<template>
    <div class="detail">
        <div class="detail-back" @click="hideDetail"></div>
        <div class="detail-ctx" v-if="apply">
            <a-icon class="icon-close" type="close"  @click="hideDetail" />
            <div class="detail-ti">{{apply.temp ? apply.temp.name : '申请'}}</div>
            <div class="detail-info">
                <div class="detail-form">
                    <div class="detail-item" :class="[val.className]" v-for="(val, key) in apply.data" :key="key">
                        <div class="detail-label">{{val.label}}</div>
                        <div class="detail-value" v-if="val.component == 'upload'">
                            <div class="file-list" v-if="val.value && val.value.length > 0">
                                <img class="file-item" :class="{video: val.type == 2}" :src="f" v-for="(f, i) in val.value" :key="i" @click="previewImg(f, val)">
                            </div>
                            <p v-else>无</p>
                        </div>
                        <div class="detail-value" v-else>{{val.value || '无'}}</div>
                    </div>
                </div>
                <div class="detail-piece">
                    <div class="piece-ti">审批流程</div>
                    <div class="flow-info">
                        <div class="flex-box align-center flow-title">
                            <span>审批人</span>
                            <span class="dot"></span>
                            <span>{{apply.statusText}}</span>
                        </div>
                        <div class="flow-admin-list" v-if="message.admin">
                            <div class="flow-admin-item" v-for="a in message.admin" :key="a.id">
                                <div class="flow-admin-name">{{a.name}} {{a.tel}}</div>
                                <div class="flow-admin-remark" v-if="a.remark">
                                    <span>{{apply.statusText}}</span>
                                    <span class="dot"></span>
                                    <span>{{a.remark.time}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="detail-piece" v-if="apply.remarks">
                    <div class="piece-ti">备注</div>
                    <div class="remark-item" >
                        <div class="remark-ti">
                            <div class="remark-name">{{apply.remark.name}}</div>
                            <div class="remark-time">{{apply.remark.time}}</div>
                        </div>
                        <div class="remark-txt">{{apply.remark.note}}</div>
                    </div>
                </div>
            </div>
            <a-space size="large" class="apply-operate" v-if="approve && apply.status == 1">
                <a-button @click="operate(2)">驳回</a-button>
                <a-button type="primary" @click="operate(3)">{{apply.type == 5 ? '回复' : '通过'}}</a-button>
            </a-space>
        </div>
        <img-viewer :list="apply ? apply.imgList : []" :current="current" v-show="show" @close="hideViewer"></img-viewer>
        <a-modal
                :title="modalOption.title"
                :maskClosable="false"
                :closable="false"
                centered
                :width="400"
                @cancel="hideModal"
                @ok="operateConfirm"
                v-model="visible">
            <a-input type="textarea" v-model="note" :rows="4" :placeholder="modalOption.placeholder"></a-input>
            <a-checkbox
                @change="$event => {open = $event.target.checked}"
                style="margin-top: 10px;"
                v-if="apply && apply.type == 5 && apply.parsedDetail && apply.parsedDetail.open == '是'">是否发布</a-checkbox>
        </a-modal>
    </div>
</template>

<script>
    import imgViewer from "../components/img-viewer";
    import {getUserId} from "../common/js/storage";
    import {dealApply} from "../common/constant/apply";

    export default {
        name: "apply-detail",
        components: {
            imgViewer
        },
        props: {
            approve: Boolean,
            message: Object
        },
        data() {
            return {
                current: null,
                show: false,
                visible: false,
                note: null,
                open: false,
                modalOption: {}
            }
        },
        computed: {
            apply() {
                let message = this.message;
                if(message) {
                    message.apply.admin = message.admin;
                    dealApply(message.apply);
                    return message.apply;
                } else {
                    return null;
                }
            },
        },
        methods: {
            hideModal() {
                this.visible = false;
                this.open = false;
                this.modalOption = {};
                this.note = null;
            },
            showModal() {
                this.visible = true;
            },
            previewImg(img, item) {
                if(item && item.type == 2) {
                    this.video = {url: img, title: item.label};
                } else {
                    this.current = img;
                    this.show = true;
                }
            },
            hideViewer() {
                this.current = null;
                this.show = false;
            },
            hideDetail() {
                this.$emit('hide');
            },
            operate(status) {
                let apply = this.apply;
                let options = {
                    status
                };
                if (status == 3) {
                    if(apply.type == 5) {
                        options.title = '回复';
                        options.placeholder = '请输入回复内容'
                    } else {
                        options.title = '备注';
                        options.placeholder = '备注说明，限制300字'
                    }
                } else if (status == 2) {
                    options = {
                        status,
                        title: '驳回',
                        placeholder: '驳回说明，必填，限制300字',
                    };
                }
                this.modalOption = options;
                this.showModal();
            },
            operateConfirm() {
                let options = this.modalOption, note = this.note, open = this.open;
                let status = options.status;
                if(status == 2 && !note) {
                    this.$message.warning("请输入驳回说明");
                    return;
                }
                this.hideModal();
                let info = this.apply;
                let temp = {
                    id: getUserId(),
                    note
                };
                let data = {
                    status,
                    flow_status: JSON.stringify(temp)
                }
                let param = {
                    url: `/apply/${info.id}`,
                    method: 'PATCH',
                    data
                }
                this.$axios(param).then(() => {
                    // 回复
                    if(status == 3) {
                        if(info.type == 5) {
                            this.updateProposal(note, open);
                        } else if(info.type == 1) {
                            this.addGroupUser();
                        }else if(info.type == 3||info.type==2) {
                            this.updateActivity();
                        }
                    } else {
                        let text = ['已驳回', '已通过'];
                        this.$message.success(text[options.status - 2]);
                    }
                    this.$emit('operate');
                    this.hideDetail();
                });
            },
            addGroupUser() {
                let apply = this.apply;
                if(apply.temp && apply.temp.group_id) {
                    this.$axios({
                        url: '/group-member',
                        method: 'POST',
                        data: {
                            group_id: apply.temp.group_id,
                            user_id: apply.user_id
                        }
                    }).then(() => {
                        this.$message.success('已通过');
                    })
                } else {
                    this.$message.success('审批流程不存在，未能成功添加成员');
                }
            },
            updateProposal(note, open) {
                let apply = this.apply;
                let data = apply.parsedDetail;
                data.apply_id = apply.id;
                data.reply = note;
                data.is_show = open * 1;
                this.$axios({
                    url: `/advice/${apply.entity_id}`,
                    method: 'PATCH',
                    data
                }).then(() => {
                    this.$message.success("已回复");
                });
            },
            updateActivity(){
                let apply = this.apply;
                let data = apply.parsedDetail;
                let body = {
                    tel: data.mobile||'',
                    name: data.name,
                    start_time: data.date || data.time[0] + ' '+ data.time[1].split('-')[0],
                    info: data.info,
                    apply_id: apply.id,
                    location: data.place||data.site,
                    img: String(data.img),
                };
                if(data.group) {
                    if(data.group.id > 0) {
                        body.group_id = data.group.id;
                    } else {
                        body.org = data.org;
                    }
                }
                this.$axios({
                    url: '/activity',
                    method: 'POST',
                    data: body
                }).then(() => {
                    this.$message.success('已通过');
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .detail {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
    }
    .detail-back {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: @modal-mask-bg;
    }

    .detail-ctx{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        bottom: 10px;
        margin: 10vh auto 0;
        width: 600px;
        max-height: 70vh;
        border: var(--border);
        background-color: @component-background;
        font-size: 14px;
        .icon-close {
            position: absolute;
            top: 8px;
            right: 8px;
            font-size: 24px;
            text-align: center;
            color: @text-color-secondary;
            cursor: pointer;
        }
    }
    .detail-info {
        flex: 1;
        overflow: auto;
        padding-bottom: 20px;
    }
    .detail-form {
        padding: 10px 0;
    }
    .detail-ti {
        flex-shrink: 0;
        padding: 12px 0;
        border-bottom: var(--border);
        font-size: 18px;
        font-weight: 700;
        text-align: center;
    }
    .detail-item {
        display: flex;
        padding: 6px 30px;
        background-color: @component-background;
        line-height: 1.4;
        &.vertical {
            flex-direction: column;
            .detail-value {
                margin: 10px 0 0;
            }
        }
    }
    .gap {
        height: 10px;
        background-color: @background-color-base;
    }
    .detail-label {
        min-width: 60px;
        color: @text-color-secondary;
    }
    .detail-value {
        margin-left: 20px;
        color: @text-color;
        text-align: justify;
    }
    .file-list {
        font-size: 0;
    }
    .file-item {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 10px 0;
        width: 100px;
        height: 100px;
        object-fit: cover;
        cursor: pointer;
        &.video {
            background-color: #000;
            &::before {
                content: '\e660';
                display: block;
                font-size: 30px;
                color: var(--back-select-font-color);
                font-family: iconfont;
                line-height: 100px;
                text-align: center;
            }
        }
    }
    .apply-operate {
        flex-shrink: 0;
        padding: 10px 0;
        border-top: var(--border);
        justify-content: center;
    }
    .person-item {
        padding: 10px 10px 0;
        background-color: var(--back-grey-color);
    }
    .person-mobile {
        margin: 0 4px;
        color: var(--tip-font-color);
    }
    .person-other {
        margin: 6px 0 10px;
    }
    .person-img-ti {
        margin-bottom: 6px;
    }
    .detail-piece {
        padding: 0 30px 16px;
        border-top: var(--border);
    }
    .piece-ti {
        padding-top: 16px;
        font-size: 16px;
        color: var(--title-font-color);
        font-weight: 600;
    }
    .flow-info {
        margin-top: 6px;
    }
    .flow-admin-list {
        margin-top: 6px;
        padding-left: 2em;
    }
    .flow-admin-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 24px;
    }
    .flow-admin-remark {
        display: flex;
        align-items: center;
    }
    .remark-item {
        padding: 10px 0;
        &:not(:first-of-type) {
            border-bottom: var(--border);
        }
    }
    .remark-ti {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--tip-font-color);
    }
    .remark-txt {
        margin-top: 6px;
        color: var(--title-font-color);
        line-height: 1.5;
    }
</style>
