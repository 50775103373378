<template>
    <div class="img-view" @mouseup="handleMouseUp">
        <div class="img-view-back"></div>
        <div class="img-view-ctx">
            <div class="img-view-close icon-close" @click="close"></div>
            <div class="img-box" id="box">
                <div class="img-area"
                     :style="{
                        width: enlarge && width ? width + 'px' : null,
                        height: enlarge && height ? height + 'px' : null,
                        transform: `translateX(${moveInfo.mx}px) translateY(${moveInfo.my}px)`
                     }"
                     v-if="img">
                    <img
                        :src="img ? img.url : ''"
                        class="view-img"
                        :class="{ani: ani && angle != 0, move: enlarge}"
                        :style="{
                            width: enlarge && width ? width + 'px' : null,
                            height: enlarge && height ? height + 'px' : null,
                            transform: `rotate(${angle * 90}deg)`
                        }"
                        @mousedown="handleMouseDown"
                        @mousemove="handleMouseMove"
                        @load="imgLoad">
                </div>
                <div class="icon-left" :class="{disable: cur <= 0}" @click="prev"></div>
                <div class="icon-right" :class="{disable: cur >= list.length - 1}" @click="next"></div>
            </div>
            <div class="img-bottom">
                <div class="img-opt">
                    <div class="img-title">{{img ? img.title : ''}}</div>
                    <div class="img-btn">
                        <div class="img-opt-icon icon-turn turned" @click="turnImg(-1)"></div>
                        <div class="img-opt-icon icon-turn" @click="turnImg(1)"></div>
                        <template v-if="enlarge !== null">
                            <div class="img-opt-icon icon-narrow" @click="enlarge=false" v-if="enlarge"></div>
                            <div class="img-opt-icon icon-enlarge" @click="enlarge=true" v-else></div>
                        </template>
                    </div>
                </div>
                <div class="img-list-box">
                    <ul class="img-list">
                        <img class="img-item" :class="{select: cur === page * size + i}" v-for="(item, i) in showList" :key="i" :src="item.url" @click="cur = page * size + i">
                    </ul>
                    <div class="icon-left" v-show="page > 0" @click="changePage(-1)"></div>
                    <div class="icon-right" v-show="page < count - 1" @click="changePage(1)"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "img-viewer",
        props: {
            list: Array, // 图片列表
            current: String, //当前预览的图片链接
        },
        data() {
            return {
                cur: null,
                angle: 0,
                enlarge: null,
                width: null, //图片实际像素宽度
                height: null, //图片实际像素高度
                cw: null, //图片容器宽
                ch: null, //图片容器高
                ani: true,
                page: 0, // 图片列表页数
                size: 12, // 列表最多显示的图片数
                moveInfo: {
                    x: null, //起始坐标x
                    y: null, //起始坐标y
                    mx: 0, //实际偏移x
                    my: 0, //实际偏移y
                    sx: 0, //起始偏移量x
                    sy: 0, //起始偏移量y
                }
            }
        },
        created() {
            document.addEventListener("keyup", this.handleKeyUp, false);
        },
        beforeDestroy() {
            document.removeEventListener("keyup", this.handleKeyUp, false);
        },
        computed: {
            img() {
                return this.list && this.list[this.cur] ? this.list[this.cur] : null;
            },
            count() {
                return this.list ? Math.ceil(this.list.length / this.size) : 0;
            },
            showList() {
                return this.list ? this.list.slice(this.page * this.size, (this.page + 1) * this.size) : [];
            }
        },
        watch: {
            current(val) {
                let list = this.list;
                if(val && list) {
                    this.cur = list.findIndex(item => item.url == val);
                } else {
                    this.cur = null;
                }
            },
            cur() {
                this.enlarge = null;
                this.angle = 0;
                this.page = Math.ceil((this.cur + 1) / this.size) - 1;
            },
            angle() {
                let info = this.moveInfo;
                this.$set(info, 'mx', 0);
                this.$set(info, 'my', 0);
            },
            enlarge(val) {
                if(!val) {
                    this.moveInfo = {
                        x: null, //起始坐标x
                        y: null, //起始坐标y
                        mx: 0, //实际偏移x
                        my: 0, //实际偏移y
                        sx: 0, //起始偏移量x
                        sy: 0, //起始偏移量y
                    }
                }
            }
        },
        methods: {
            changePage(num) {
                let page = this.page;
                if((page <= 0 && num < 0) || (page >= this.count && num > 0)) return;
                this.page += num;
            },
            handleMouseDown(e) {
                if(!this.enlarge) return;
                e.preventDefault();
                this.ani = false;
                this.moveInfo.x = e.clientX;
                this.moveInfo.y = e.clientY;
                this.moveInfo.sx = this.moveInfo.mx;
                this.moveInfo.sy = this.moveInfo.my;
            },
            handleMouseMove(e) {
                e.preventDefault();
                let info = this.moveInfo;
                if(info.x === null) {
                    return;
                }
                let angle = this.angle;
                let x = e.clientX, y = e.clientY;
                //此时的坐标偏移量
                let ox = x - info.x + info.sx, oy = y - info.y + info.sy;
                // 因为图片默认居中 如果偏移量超出图片和容器的差的一半 则视为达到容器边界
                let maxOffsetWidth = (angle % 2 == 0 ? this.width : this.height) - this.cw;
                let maxOffsetHeight = (angle % 2 == 0 ? this.height : this.width) - this.ch;
                maxOffsetWidth = maxOffsetWidth >= 0 ? maxOffsetWidth : 0;
                maxOffsetHeight = maxOffsetHeight >= 0 ? maxOffsetHeight : 0;
                if(Math.abs(ox) > maxOffsetWidth / 2) {
                    ox = ox > 0 ? maxOffsetWidth / 2 : -1 * maxOffsetWidth / 2
                }
                if(Math.abs(oy) > maxOffsetHeight / 2) {
                    oy = oy > 0 ? maxOffsetHeight / 2 : -1 * maxOffsetHeight / 2
                }
                this.$set(info, 'mx', ox);
                this.$set(info, 'my', oy);
            },
            handleMouseUp(e) {
                if(!this.enlarge) return;
                e.preventDefault();
                this.moveInfo.x = null;
                this.moveInfo.y = null;
                this.ani = true;
            },
            handleKeyUp(e) {
                if(e.code == 'ArrowLeft') {
                    this.prev();
                } else if(e.code == 'ArrowRight') {
                    this.next();
                }
            },
            imgLoad(e) {
                let img = e.target;
                let w = img.naturalWidth, h = img .naturalHeight;
                this.width = w;
                this.height = h;
                let box = document.querySelector("#box");
                this.cw = box.clientWidth;
                this.ch = box.clientHeight;
                //只要图片原始的宽或者高超出了容器的尺寸显示放大按钮
                if(w > this.cw || h > this.ch) {
                    this.enlarge = false;
                }
            },
            prev() {
                if(this.cur <= 0) return;
                this.cur -= 1;
            },
            next() {
                if(this.cur >= this.list.length - 1) return;
                this.cur += 1;
            },
            close() {
                this.$emit("close");
            },
            turnImg(num) {
                if(this.angle >= 3 && num > 0 || this.angle <= -3 && num < 0) {
                    this.angle = 0;
                } else {
                    this.angle += num;
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .img-view {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 11;
        color: @white;
    }
    .img-view-back {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.8);
    }
    .img-view-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 36px;
        height: 36px;
        background-color: #000;
        transform: translateX(100%);
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
    }
    .img-view-ctx {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        width: 1200px;
        background-color: #000;
    }
    .img-box,
    .img-list-box{
        .icon-left,
        .icon-right {
            position: absolute;
            top: 0;
            margin: auto 0;
            width: 48px;
            height: 48px;
            font-size: 48px;
            cursor: pointer;
            text-align: center;
            line-height: 48px;
            text-shadow: 0 0 2px #000;
            &.disable {
                color: #bbb;
            }
        }
        .icon-left {
            left: 0;
        }
        .icon-right {
            right: 0;
        }
    }
    .img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 160px;
        overflow: hidden;
        .icon-left,
        .icon-right {
            bottom: 60px;
        }
    }
    .img-area {
        width: 100%;
        height: 100%;
    }
    .view-img {
        display: block;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        object-fit: contain;
        &.ani {
            transition: transform .3s;
        }
        &.move {
            cursor: move;
        }
    }
    .img-opt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        padding: 0 40px;
    }
    .img-btn {
        display: flex;
        align-items: center;
    }
    .img-opt-icon {
        margin-left: 20px;
        font-size: 20px;
        cursor: pointer;
        &.turned {
            transform: rotateY(180deg);
        }
    }
    .img-title {
        font-size: 16px;
        text-align: center;
    }
    .img-bottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding-bottom: 10px;
    }
    .img-list-box {
        position: relative;
        height: 90px;
        .icon-left,
        .icon-right {
            bottom: 0;
        }
    }
    .img-list {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .img-item {
        display: block;
        width: 90px;
        height: 90px;
        object-fit: cover;
        cursor: pointer;
        &.select {
            border: 4px solid @primary-color;
        }
    }
</style>
