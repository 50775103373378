<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: "Entity"
    }
</script>

<style scoped>

</style>
