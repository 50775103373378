<template>
    <data-list
        ref="dataList"
        class="content"
        add-route="AddEntity"
        :add-btn="`添加${conf.name}`"
        :url="conf.url"
        :name="conf.name"
        :query="conf.query"
        :columns="columns"
        :default-query="conf.defaultQuery"
        :search-key-type="searchKeyType"
        @rowClick="showDetail"
        @edit="showEdit"
        v-if="conf">
        <template #title>
            <span>{{conf.listTitle}}</span>
        </template>
        <template v-slot:query="{form}" v-if="conf.queryForm">
            <a-form-model-item :label="item.label" v-for="item in conf.queryForm" :key="item.key">
                <component
                    :is="item.component"
                    :style="item.style"
                    v-model="form[item.key]"
                    v-bind="item.props"
                    v-on="getListeners(item)">
                    <template v-if="item.component == 'a-radio-group'">
                        <a-radio :value="typeof opt === 'object' ? opt.value : opt" v-for="(opt, j) in item.options" :key="j">{{typeof opt === 'object' ? opt.text : opt}}</a-radio>
                    </template>
                    <template v-else-if="item.component == 'a-checkbox-group'">
                        <a-checkbox :value="typeof opt === 'object' ? opt.value : opt" v-for="(opt, j) in item.options" :key="j">{{typeof opt === 'object' ? opt.text : opt}}</a-checkbox>
                    </template>
                </component>
            </a-form-model-item>
        </template>
    </data-list>
</template>

<script>
    import entityConfig from "../common/constant/entity";
    import {goToDetailRoute} from "../common/js/tool";

    export default {
        name: "EntityList",
        data() {
            return {}
        },
        computed: {
            type() {
                return this.$route.params.type
            },
            conf() {
                let type = this.type;
                return entityConfig[type];
            },
            searchKeyType() {
                let conf = this.conf;
                return conf && conf.searchKeyType || {name: true};
            },
            columns() {
                let conf = this.conf;
                return conf && conf.columns ? conf.columns(this) : [];
            },
            update() {
                return this.$store.getters.update;
            }
        },
        watch: {
            update(val) {
                if(val && val.type == this.type) {
                    this.updateList();
                    this.$store.commit("changeUpdate", null);
                }
            },
            conf() {
                this.setTitle();
            }
        },
        created() {
            this.setTitle();
        },
        methods: {
            setTitle() {
                let conf = this.conf;
                if(conf) {
                    document.title = `${conf.name}管理`
                } else if(this.type) {
                    this.$router.replace({name: 'NotFound'});
                }
            },
            getListeners(item) {
                let res = null;
                if(item.update && item.update.length > 0) {
                    res = {};
                    item.update.forEach(type => {
                        res[type] = this.updateList;
                    })
                }
                return res;
            },
            updateList() {
                this.$refs.dataList.getList();
            },
            showDetail({item}) {
                goToDetailRoute(this, {item, type: this.type})
            },
            showEdit(item) {
                goToDetailRoute(this, {item, type: this.type, edit: true})
            }
        }
    }
</script>

<style scoped lang="less">
</style>
